import { GET_PRODUCTS } from './action-types'
import axios from 'axios';

// Create action to get products from an API.
export function getProducts() {
    return (dispatch) => {
        axios.get('https://btm-be-1.herokuapp.com/api/products/getData')
            .then(response => {
                const products = response.data.Products;
                dispatch({
                    type: GET_PRODUCTS,
                    payload: products
                })
            })
    }
}