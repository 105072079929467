import React from 'react';
import "../../assets/css/Infra.css";
import Hospital from "../../assets/images/NavBar/HospitalIcon.svg";
import Government from "../../assets/images/NavBar/GovernmentIcon.svg";
import Hotel from "../../assets/images/NavBar/HotelIcon.svg";
import Hostel from "../../assets/images/NavBar/HostelIcon.svg";
import Wholesellar from "../../assets/images/NavBar/WholesellarIcon.svg";
import Retailer from "../../assets/images/NavBar/RetailersIcon.svg";
import YouTube from 'react-youtube';


export default function Infra() {
    return (
        <div className="infra-box">
            <h2 className="infrastructure-title">Infrastructure</h2>

            <div className="infra-parent">
                <div className="width-50 video-player-container">
                    <YouTube
                        videoId={'lAzAoWZ6zp8'}
                        id=""
                        className="video-player"
                        containerClassName=""
                    />
                    <p className="video-text">
                        A Brief Introduction To Textiles. Textiles are not just Clothing and Household Fabrics but finds application in several fields. The Study of Textiles will provide knowledge of fibres, construction,usage, durability, etc.
                    </p>
                </div>
                <div className="abs-width-50">
                    <h6 className="h6-text">
                        Currently Dealing With
                    </h6>
                    <div className="row-flex">
                        <div className="ellipse-container">
                            <img alt="Hospitals" className="ellipse" src={Hospital}></img>
                            <h6 className="ellipse-text">
                                Hospitals
                            </h6>
                        </div>
                        <div className="ellipse-container">
                            <img alt="Hotels" className="ellipse" src={Hotel}></img>
                            <h6 className="ellipse-text">
                                Hotels
                            </h6>
                        </div>

                        <div className="ellipse-container">
                            <img alt="Wholesellars" className="ellipse" src={Wholesellar}></img>
                            <h6 className="ellipse-text">
                                Wholesellars
                            </h6>
                        </div>
                        <div className="ellipse-container">
                            <img alt="Retailers" className="ellipse" src={Retailer}></img>
                            <h6 className="ellipse-text">
                                Retailers
                            </h6>
                        </div>
                        <div className="ellipse-container">
                            <img alt="Government" className="ellipse" src={Government}></img>
                            <h6 className="ellipse-text">
                                Government
                            </h6>
                        </div>
                        <div className="ellipse-container">
                            <img alt="Hostels" className="ellipse" src={Hostel}></img>
                            <h6 className="ellipse-text">
                                E-Sellers
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
