import React, { useState } from "react";
import "../../assets/css/nav.css";
import Logo from "../../assets/images/NavBar/BtmLogoTop.svg";
import callIcon from "../../assets/images/NavBar/CallIcon.svg";
import { Link } from "react-router-dom";
import Needle from "../../assets/images/needle.svg";
import Close from "../../assets/images/NavBar/close.svg";

import Modal from "react-modal";

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="top-menu-bar">
      <Link to="/">
        <img src={Logo} alt="Logo" className="btm-logo-top" />
      </Link>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
      >
        <div className="centered-flex">
          <h3>Send Enquiry</h3>
          <h3>
            <a href="tel:99916-53558">
              <img src={callIcon} alt="Call Us" className="call-icon-modal" />
              99916-53558
            </a>
          </h3>
        </div>
        <div className="flex-list">
          <ul>
            <li>
              <Link to="/about-us" onClick={toggleModal}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/products" onClick={toggleModal}>
                Products
              </Link>
            </li>
            <li>
              <Link to="/gallery" onClick={toggleModal}>
                Gallery
              </Link>
            </li>
            <li>
              <a href="https://blog.btmtextile.in">Blog</a>
            </li>
          </ul>
        </div>
        <div className="mdl-map">
          <img
            src="https://ik.imagekit.io/ecm6kbqye09/btmMap_IvO-7eaOr.png"
            alt=""
            className="map-img-modal"
          ></img>
        </div>
      </Modal>
      <div className="abs-right">
        <a href="tel:99966-88869">
          <img src={callIcon} alt="Call Us" className="call-icon" />
        </a>
        {!isOpen ? (
          <img
            src={Needle}
            onClick={toggleModal}
            alt="Call Us"
            className="menu-icon"
          />
        ) : (
          <img
            src={Close}
            onClick={toggleModal}
            alt="Call Us"
            className="close-icon"
          />
        )}
        <a href="tel:99916-53558">
          <p className="phone-number">99916-53558</p>
        </a>
        <Link to="/contact">
          <p className="send-equiry">Send Enquiry</p>
        </Link>
      </div>
    </div>
  );
}
