import React from 'react';
import LottieAnimation from '../Lottie';
import Map from '../../assets/animations/IndiaMapJSON2.json';

const MapAnim = () => {

    return ( 
         <div className='map-div'> 
           <LottieAnimation lotti={Map} height={500} width={500} className="lottie-map"/>
        </div>
    )
}


export default MapAnim; 
