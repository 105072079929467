import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import axios from "axios";
import Gallery from "../Gallery";
import { HeroSection, About, Contact, Infra, Beyond, Products } from "../../index";
import { createRoutes } from "../../Utils/ProductsHelper";
import Blocks from "../Layout/Blocks";
import { useMediaQuery } from "react-responsive";
import ProductsMob from "../Products/ProductMob";
import { connect } from "react-redux";
import { getProducts } from "../../actions/productsActions"
import NotFound from "../NotFound";

function Routes(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 824px)" });
  const isTabletOrMobile2 = useMediaQuery({ query: "(min-width: 824px)" });



  useEffect(() => {
    props.getProducts();
  }, []);

  return (
    <Switch>
      <Route path="/about-us">
        <About />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/infrastructure">
        <Infra></Infra>
      </Route>
      <Route path="/beyond-business">
        <Beyond></Beyond>
      </Route>
      <Route path="/products">
        <Blocks headingName="Our Products" rowData={props.products}
          title="Collection of all Types of Cotton Fabric product by BTM Textile."
          description="Here you can find all types of cotton finest products like Carpets, Dohar, Wedding Set, Cushion Set, Fibre Pillow, Jacquard bedsheet, Microfibre Bedsheet, Sofa Cover, Etc."
        />
      </Route>
      <Route path="/gallery">
        <Gallery />
      </Route>

      {props.products.map((item) => {
        return createRoutes(item);
      })}
      <Route path="/" exact>
        <HeroSection />
        <About />
        {isTabletOrMobile && <ProductsMob />}
        {isTabletOrMobile2 && <Products />}
        <Infra />
        <Beyond />
        <Contact />
      </Route>
      <Route path="" component={NotFound} />
    </Switch>
  );
}

const mapStateToProps = (state) => {
  return {
    products: state.products
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => { dispatch(getProducts()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes);