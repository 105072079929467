import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "react-bootstrap";
import "../../src/assets/css/gallery.css";
import { Helmet } from "react-helmet";

function Gallery() {
  const [gallery, setGallery] = useState([]);

  const fetch = () => {
    axios
      .get(`https://btm-be-1.herokuapp.com/api/gallery/getData`)
      .then((response) => {
        setGallery(response.data.Data);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const Cards = gallery.map((item, index) => {
    return (
      <Card style={{ width: "18rem" }} key={index}>
        <Card.Img variant="top" src={item.url} height="150" width="150" />
        <Card.Body>
          <Card.Title>{item.heading}</Card.Title>
          <Card.Text>{item.description}</Card.Text>
        </Card.Body>
      </Card>
    );
  });

  return (
    <div className="root-gallery">
      <Helmet>
        <title>Gallery</title>
        <meta name="description" content="Gallery" />
      </Helmet>
      <h2 className="infrastructure-title">BTM GALLERY</h2>
      <div className="image-holder">{Cards}</div>
    </div>
  );
}

export default Gallery;
