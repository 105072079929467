import axios from "axios";
import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "../../assets/css/master.css";

function Main() {
  const [images, setImages] = useState([]);
  const fetch = () => {
    axios.get("https://btm-be-1.herokuapp.com/api/home/getData").then((res) => {
      setImages(res.data.Data);
    });
  };
  useEffect(() => {
    fetch();
  }, []);

  const carousel = images.map((item, index) => {
    return (
      <Carousel.Item className="h-90" interval="3000" key={index}>
        <img
          className="d-block w-100 h-90"
          src={item.image}
          alt="First slide"
        />
      </Carousel.Item>
    );
  });
  return (
    <div className="hero-bg">
      <h4 className="abs-between max-width-100 main-heading">
        The Leader in Fine Rug Carpet & premium bedsheets{" "}
      </h4>
      <Carousel fade> {carousel}</Carousel>
      <div className="dark-overlay-sec dark-overlay-carousel"></div>
    </div>
  );
}

export default Main;
