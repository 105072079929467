import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../assets/css/Products.css";
import axios from "axios";
import { connect } from "react-redux";
import { getProducts } from "../../actions/productsActions";

import { Link } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
};

function Products(props) {

  useEffect(() => {
    props.getProducts();
  }, []);

  const CarouselItems = props.products.map((item, index) => {
    return (
      <div
        className="product-car img-pro1"
        style={{ backgroundImage: `url(${item.data})` }}
        key={index}
      >
        <Link to={item.link}>
          <div className="dark-overlay">
            <h3>{item.name}</h3>
          </div>
        </Link>
      </div>
    );
  });
  return (
    <div className="products-container">
      <h2 className="our-products-title">Our Products</h2>

      <div className="flex-arrows">
        <Carousel
          responsive={responsive}
          className="carousel-multi"
          infinite={true}
          autoPlay={true}
          arrows={true}
        >
          {CarouselItems}
        </Carousel>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    products: state.products
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => { dispatch(getProducts()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);