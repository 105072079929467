import React from 'react';
import '../../assets/css/nav.css';
import { Link } from "react-router-dom";

export default function SecondaryBar() {
    return (
        <div className="second-option-bar">
            <ul className="second-option-list">
                <li className="second-list-grey"><Link to="/jaquard">Jacquard</Link></li>
                <li className="second-list-grey"><Link to="/micro-fibre">Microfibre</Link></li>
                <li className="second-list-grey"><Link to="/hotel-size">Hotel Bedsheet</Link></li>
                <li className="second-list-grey"><Link to="/king-size">King Size</Link></li>
                <li className="second-list-grey"><Link to="/cotton-bedsheets">Queen Size</Link></li>
            </ul>
        </div>
    )
}
