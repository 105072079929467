import React, { useState } from "react";
import "../../assets/css/Contact.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";

export default function Contact() {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const nameHandler = (e) => {
    setData({
      ...data,
      name: e.target.value,
    });
  };

  const emailHandler = (e) => {
    setData({
      ...data,
      email: e.target.value,
    });
  };

  const phoneHandler = (e) => {
    setData({
      ...data,
      phone: e.target.value,
    });
  };

  const messageHandler = (e) => {
    setData({
      ...data,
      message: e.target.value,
    });
  };

  const contactHandler = (e) => {
    e.preventDefault();
    const body = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      message: data.message,
    };
    axios.post("https://btm-be-1.herokuapp.com/contact", body).then((res) => {
      if (res.data.success) {
        toast("Message Sent Successfully");
        document.getElementById("contact-form").reset();
        setData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        toast.warn("Failed..");
      }
    });
  };

  return (
    <div className="contact-box">
      <ToastContainer />
      <div className="group-1">
        <div className="title-cont">
          <h2 className="contact-title">CONTACT US</h2>
        </div>
      </div>
      <div className="flex" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <a href="https://www.facebook.com/BTM-237124734912028">
          <AiFillFacebook style={{ height: "30px", fontSize: "30px", color: "white", marginRight: "7px" }} />
        </a>
        <a href="https://www.instagram.com/textilebtm/">
          <AiFillInstagram style={{ height: "30px", fontSize: "30px", color: "white", marginRight: "7px" }} />
        </a>
        <a href="http://linkedin.com/in/btm-bansal-textile-manufacturers-6b897121b">
          <AiFillLinkedin style={{ height: "30px", fontSize: "30px", color: "white", marginRight: "7px" }} />
        </a>
        <a href="https://twitter.com/textileBTM">
          <FaTwitter style={{ height: "30px", fontSize: "30px", color: "white", marginRight: "7px" }} />
        </a>
      </div>

      <div className="block-container">
        <div className="in-block-20">
          <p className="address-text">
            Inside Maharaja Agarsen Towel Market, Gohana Road-Panipat, Jagguran
            Ram Colony, Panipat, Haryana-132103
          </p>
          <a
            href="https://www.google.com/maps/place/B.T.M./@29.3786597,76.9700498,15.55z/data=!4m5!3m4!1s0x390ddb323feb9ea5:0x16b1d5c30041a33c!8m2!3d29.3811569!4d76.9695233"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://ik.imagekit.io/ecm6kbqye09/btmMap_IvO-7eaOr.png"
              alt=""
              className="map-img"
            ></img>
          </a>
        </div>
        <div className="in-block-30 block-2">
          <p className="feedback-text">
            To share a feedback or grievenace, submit a ticket below or write to
            us at info@btmtextile.in We will get in touch shortly.
          </p>
          <p className="call-text">
            You could always give us a call at <br></br>
            <p className="phone-nums">
              <a href="tel:+91 798-855-8667">+91 798-855-8667</a>
            </p>
            <p className="phone-nums">
              <a href="tel:+91 989-606-7755">+91 989-606-7755</a>
            </p>
            <p className="phone-nums">
              <a href="tel:+91 921-570-1227">+91 921-570-1227</a>
            </p>
            <p className="phone-nums">
              <a href="tel:+91 946-662-2053">+91 946-662-2053</a>
            </p>
            <p className="phone-nums">
              <a href="tel:+91 816-812-8573">+91 816-812-8573</a>
            </p>
            <p className="phone-nums">
              <a href="tel:+91 999-668-8869">+91 999-668-8869</a>
            </p>
            <p className="phone-nums">
              <a href="tel:+918199994035">+91 819-999-4035</a>
            </p>
          </p>
        </div>
        <div className="in-block-30 c-form">
          <form id="contact-form">
            <input
              type="text"
              className="inp-text"
              name="name"
              onChange={nameHandler}
              placeholder="Name"
              autoComplete="none"
              required
            ></input>
            <input
              type="text"
              className="inp-text"
              name="email"
              onChange={emailHandler}
              autoComplete="none"
              placeholder="Email"
              required
            ></input>
            <input
              type="text"
              className="inp-text"
              name="phone"
              onChange={phoneHandler}
              autoComplete="none"
              placeholder="9990099900"
              required
              maxLength="10"
            ></input>
            <input
              type="text"
              className="inp-text lg-size"
              name="message"
              autoComplete="none"
              onChange={messageHandler}
              placeholder="Enter your message"
              maxLength="100"
              required
            ></input>
            <input
              type="submit"
              onClick={contactHandler}
              className="red-btn"
              value="Submit"
            ></input>
          </form>
        </div>
      </div>
      <h6 className="dev-guy">

        <a href="https://www.theinfosecguy.tech">
          Developed by Keshav Malik
        </a>
      </h6>
      <div className="bg-image">
      </div>
    </div>
  );
}
