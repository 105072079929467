import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route } from "react-router-dom";
import "../assets/css/Layout.css";

export const createRoutes = (arr) => {
  const component = arr.subcategory.map((item, index) => {
    return (
      <div className="carousel-container-products non-home" key={index}>
        <Carousel fade className="product-carousel-page">
          {item.images.map((image) => {
            return (
              <Carousel.Item className="product-carousel-item">
                <img
                  className="d-block w-100 bedsheet-img"
                  src={image}
                  alt={item.name}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
        <div className="bedsheet-desc">
          <ul className="bedsheet-desc-ul">
            <li>
              <h3 className="bedsheet-desc-heading">{item.name}</h3>
            </li>
            <li>
              <div className="p-layout-content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
            </li>
            <li>
              <a
                href={`https://wa.me/+919991653558/?text=Enquiry%20for%20${item.name}`}
              >
                <button className="red-btn-enquiry">Bulk Enquiry</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  });
  return (
    <Route path={arr.link}>
      <div className="multi-layout-container">
        <h2 className="layout-product-heading">{arr.name}</h2>
        <div className="box">
          <div className="multi-product-container h-50">{component}</div>
        </div>
        <div>
          <div className="p-seo-content">
            <p dangerouslySetInnerHTML={{ __html: arr.seoContent }}>
            </p>
          </div>
        </div>
      </div>
    </Route>
  );
};
