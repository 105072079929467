import React from "react";
import "../../assets/css/nav.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function OptionBar() {
  const location = useLocation();

  return (
    <div className="option-bar">
      <ul className="option-list">
        <li
          className={`list-grey ${location.pathname !== "/" ? "grey-no" : ""}`}
        >
          <Link to="/about-us">About Us</Link>
        </li>
        <li
          className={`list-grey ${location.pathname !== "/" ? "grey-no" : ""}`}
        >
          <Link to="/products">Products</Link>
        </li>
        <li
          className={`list-grey ${location.pathname !== "/" ? "grey-no" : ""}`}
        >
          <Link to="/infrastructure">Infrastructure</Link>
        </li>
        <li
          className={`list-grey ${location.pathname !== "/" ? "grey-no" : ""}`}
        >
          <Link to="/beyond-business">Beyond Business</Link>
        </li>
        <li
          className={`list-grey ${location.pathname !== "/" ? "grey-no" : ""}`}
        >
          <Link to="/gallery">Gallery</Link>
        </li>
        <li
          className={`list-grey ${location.pathname !== "/" ? "grey-no" : ""}`}
        >
          <a href="https://blog.btmtextile.in">Blog</a>
        </li>
      </ul>
    </div>
  );
}
