import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../assets/css/About.css";
import Map from "../../components/Lottie/Maps";
import { Helmet } from "react-helmet";

export default function About() {
  const [content, setContent] = useState({
    name: "",
    data: "",
  });

  const fetch = () => {
    axios
      .get(`https://btm-be-1.herokuapp.com/about/getData`)
      .then((response) => {
        // response.data.Data[0];
        setContent({
          ...content,
          name: response.data.Data[0].name,
          data: response.data.Data[0].data,
        });
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className="flex-center about-container">
      <Helmet>
        <title>About BTM Textiles - The company is situated in Panipat, Haryana since 1980.</title>
        <meta name="description" content="BTM Textile Manufacturers company produce a huge range of textile products that extends from yarns and fabrics to home decors. The company is situated in Panipat, India." />
      </Helmet>
      <h2 className="about-us-title">About Us</h2>
      <div className="grey-bg">
        <p className="demo-text">{content.data}</p>
      </div>
      <Map />
    </div>
  );
}
