import React from "react";
import "../../assets/css/ProductsRow.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Blocks(props) {
  const products = props.rowData.map((item, index) => {
    return (
      <Link to={item.link} className="flex-just-center" key={index}>
        <Helmet>
          <title>{props.title}</title>
          <meta name="description" content={props.description} />
        </Helmet>
        <div
          className="row-product-div"
          style={{
            background: `url(${item.data})`,
            backgroundSize: "cover",
            backgroundPosition: "Center",
          }}
        >
          <div className="dark-overlay-product-div">
            <h3>{item.name}</h3>
          </div>
        </div>
        {props.showEnquiryBtn ? (
          <a href="https://wa.me/+919991653558/?text=Enquiry%20for%20Wedding%20Sets">
            <button className="red-btn-enquiry">Post Enquiry</button>
          </a>
        ) : (
          ""
        )}
      </Link>
    );
  });
  return (
    <div className="products-row-container">
      <h2 className="row-our-products-title">{props.headingName}</h2>
      <div className="flex-wrap-row cd-1">{products}</div>
    </div>
  );
}
