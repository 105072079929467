import React from 'react'

function NotFound() {
    return (
        <div className="nf-root">
            <img src="https://ik.imagekit.io/ecm6kbqye09/animation_300_ksveqfit_Z_46_FCYFr.gif?updatedAt=1630132400781" />
        </div>
    )
}

export default NotFound
