import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import "../../assets/css/Products.css";
import { Link } from "react-router-dom";
import "../../assets/css/ProductsMob.css";
import { connect } from "react-redux";
import { getProducts } from "../../actions/productsActions"

function ProductsMob(props) {
  useEffect(() => {
    props.getProducts();
    console.log("Products: ", props.products[0]);
  }, []);


  const mProducts = props.products.map((data, index) => {
    return (
      <Link to={data.link} key={index}>
        <div
          className="m-products-row"
          style={{
            background: `url(${data.data})`,
            backgroundSize: "cover",
            backgroundPosition: "Center",
          }}
        >
          <div className="m-dark-overlay-sec"></div>
          <h3 className="m-product-name">{data.name}</h3>
        </div>
      </Link>
    );
  });

  return (
    <div className="productsmob-container">
      <h3 className="mob-products-title">Our Products</h3>
      <div className="m-products-col">{mProducts}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    products: state.products
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => { dispatch(getProducts()) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsMob);