import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

export { default as About } from "./components/HomeScreen/About";
export { default as Routes } from "./components/Routes/Routes";
export { default as Beyond } from "./components/HomeScreen/Beyond";
export { default as Contact } from "./components/HomeScreen/Contact";
export { default as Infra } from "./components/HomeScreen/Infra";
export { default as HeroSection } from "./components/HomeScreen/HeroSection";
export { default as Nav } from "./components/Navbar/Nav";
export { default as OptionBar } from "./components/Navbar/OptionBar";
export { default as ProductMob } from "./components/Products/ProductMob";
export { default as Products } from "./components/Products/Products_HomePage";
export { default as SecondaryBar } from "./components/Navbar/SecondaryBar";