import React from "react";
import "../../assets/css/Beyond.css";
import { Helmet } from "react-helmet";

const bbData = [
  {
    img: "https://ik.imagekit.io/ecm6kbqye09/BB/23824522_1608056705918028_2588867945132195840_n_5prnXo8Sb.png",
    data: "A cow is considered as the symbol of earth because it gives so much yet asks nothing in return and Mother of all Animals. According to it, a cow is a divine animal. In all parts of her body reside all the gods. She eats petty ordinary things but converts them into nectar and distributes it among the human beings.",
    heading: "Feeding Cows",
  },
  {
    img: "https://ik.imagekit.io/ecm6kbqye09/BB/Tree_Plantation_opoAlkfoN.png",
    data: "Nature is pleased with Simplicity - Issac Newton Our company plants more trees to balance our Nature as they can effectively make a change.Trees absorbs air and transpire it to atmosphere with filtering, controlling levels of humidity & regulates water level. ",
    heading: "Planting Trees",
  },
  {
    img: "https://ik.imagekit.io/ecm6kbqye09/BB/FeedingBirds_RzKa38zmLS.png",
    data: "Always do the things that provide pleasure and calmness to our minds Spending time with nature makes a huge difference.Taking care of birds and feeding them gives a positive addon to our life's karma & helps us to get liberated from past life debts.",
    heading: "Feeding Birds",
  },
  {
    img: "https://ik.imagekit.io/ecm6kbqye09/BB/BlanketDistribution_c_sQaXEX_.png",
    data: "The Company has Contributed some amount of money on ' Blanket Distribution ' to identify and distribute blankets to the needy and underprivileged.The vision is to empower the underprivileged senior citizens and the destitute and help them lead a life of dignity and self - respect.",
    heading: "Distributing Blankets",
  },
];

const bbComp = bbData.map((item, index) => {
  return (
    <div className="bb-row" key={index}>
      <Helmet>
        <title>Beyond Business About Our Company At BTM.</title>
        <meta name="description" content="Beyond Business About Our Company At BTM (Textiles) we are all the time concerned about the way we create wealth, the way we conduct our business and we try to Feeding Cows, Planting Trees,  Feeding Birds, Distributing Blankets." />
      </Helmet>
      <div
        className="inline-bb-50 bb-img-root"
        style={{
          background: `url(${item.img})`,
          backgroundSize: "cover",
          backgroundPosition: "Center",
        }}
      >
        {/* <img src={item.img} clas="bb-img"></img> */}
      </div>
      <div className="inline-bb-50 content-bb">
        <h4 className="bb-head-text">{item.heading}</h4>
        <p className="bb-demo-text">{item.data}</p>
      </div>
    </div>
  );
});

export default class Contact extends React.Component {
  render() {
    return (
      <div className="bb-root">
        <h2 className="beyond-bussiness-title">Beyond Business</h2>
        {bbComp}
      </div>
    );
  }
}
