// combine all reducers
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import products from "../reducers/productsReducer";
import { getProducts } from "../actions/productsActions";
import thunk from 'redux-thunk';

const middleware = [thunk];

const rootReducer = combineReducers({ products });
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));
// store.dispatch(getProducts);

export default store;