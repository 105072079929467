import React from "react";
import LottieAnimation from "../Lottie";
// import Loading from '../../assets/animations/sewingmachineLoader_JSON.json';
import Loading from "../../assets/animations/sewingmachineLoader_JSON.json";

const Loader = () => {
  return (
    <div className="loader-div">
      <LottieAnimation lotti={Loading} height={200} width={200} />
    </div>
  );
};

export default Loader;
