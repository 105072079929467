import "./App.css";
import { Nav, OptionBar } from "./index";

import Routes from "./components/Routes/Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Loader from "./components/Lottie/Loader";
import store from "./store/store"
import "./fonts/Gobold Hollow Bold Italic.otf";
import "./fonts/AbhayaLibre-ExtraBold.ttf";
import "./fonts/Eina02-BoldItalic.ttf";
import "./fonts/Eina02-Bold.ttf";
import "./fonts/Eina02-Light.ttf";
import "./fonts/Eina02-LightItalic.ttf";
import "./fonts/Eina02-Regular.ttf";
import "./fonts/Eina02-RegularItalic.ttf";
import "./fonts/Eina02-SemiBold.ttf";
import "./fonts/Eina02-SemiboldItalic.ttf";
import "./fonts/Gobold Hollow Bold Italic.otf";
import "./fonts/Gobold Hollow Bold.otf";
import "./fonts/BENGUIAT.TTF";
import { Provider } from "react-redux";

function App() {
  const [loading, setLoading] = useState(true);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };
  const wait = async (milliseconds = 4000) => {
    await sleep(milliseconds);
    setLoading(false);
  };
  useEffect(() => {
    wait(4000);
  });

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Nav />
            <OptionBar></OptionBar>
            <Routes />
          </div>
          <a
            href="https://wa.me/+9199916-53558?text=Hello%20How%20can%20we%20help%20you?%20"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa fa-whatsapp whatsapp-icon"></i>
          </a>
        </Router>
      </Provider>
    );
  }
}

export default App;
